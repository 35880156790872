<template>
    <!--contents-->
    <div id="contents" ref="top">
            <!--콘텐츠-->
            <div class="conBox" id="scrollArea">
                <!--신규환자 등록-->
                <div class="patientEnroll">
                    <div class="box">
                        <v-form ref="registForm" lazy-validation>
                            <ul class="cont">
                                <li>
                                    <div style="display: flex; justify-content: space-between;">
                                        <p class="styleTit">기본정보</p>
                                        <button v-if="!isDetail" class="btn danger small" @click="deletePatient($event)" v-ripple>환자 삭제</button>
                                    </div>
                                    <table class="tbl tblInp">
                                        <caption>환자 기본정보</caption>
                                        <colgroup>
                                            <col style="width:20%"/>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>환자번호</th>
                                                <td>
                                                    {{ formValues.patientSn }}
                                                    <!-- <Input 
                                                        :type="'text'"
                                                        :name="'patientSn'"
                                                        :required=true
                                                        :width="'fullWidth'"
                                                        v-model="formValues.patientSn"
                                                        :disabled="true"
                                                        :maxLength="11"
                                                        :fnName="none()"
                                                    /> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>환자명</th>
                                                <td>
                                                    {{ formValues.name }}
                                                    <!-- <Input 
                                                        :type="'text'"
                                                        :name="'name'"
                                                        :required=true
                                                        :width="'fullWidth'"
                                                        v-model="formValues.name"
                                                        :disabled="true"
                                                        :fnName="none()"
                                                        :maxLength="20"
                                                    /> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>생년월일</th>
                                                <td>
                                                     {{ selectedYear }}년 {{ selectedMonth }}월 {{ selectedDay }}일
                                                    <!-- <div class="inpSet">
                                                        <label>
                                                            <select v-model="selectedYear" @change="getBirth('year', $event)" :value="selectedYear" :disabled="true">
                                                                <option v-for="(year, index) in years" :key="index" :value="year">{{ year }}</option>
                                                            </select>
                                                        </label>년
                                                        <label>
                                                            <select v-model="selectedMonth" @change="getBirth('month', $event)" :value="selectedMonth" :disabled="true">
                                                                <option v-for="(month, index) in months" :key="index" :value="month">{{ month }}</option>
                                                            </select>
                                                        </label>월
                                                        <label>
                                                            <select v-model="selectedDay" @change="getBirth('day', $event)" :value="selectedDay" :disabled="true">
                                                                <option v-for="(day, index) in days" :key="index" :value="day">{{ day }}</option>
                                                            </select>
                                                        </label>일
                                                    </div> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>성별</th>
                                                <td>
                                                    {{ formValues.sex === 0? "남":"여" }}
                                                    <!-- <div class="inpSet">
                                                        <v-radio-group row v-model="formValues.sex" density="compact" hide-details="auto" :disabled="true">
                                                            <v-radio label="남" :value="0"></v-radio>
                                                            <v-radio label="여" :value="1"></v-radio>
                                                        </v-radio-group>
                                                    </div> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>담당의</th>
                                                <td>{{ formValues.doctorsNm }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    <p class="styleTit">추가정보</p>
                                    <table class="tbl tblInp">
                                        <caption>환자 추가정보</caption>
                                        <colgroup>
                                            <col style="width:20%"/>
                                            <col />
                                            <col style="width:20%"/>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>본인 체중</th>
                                                <td>
                                                    <div class="spaceBetween">
                                                        <Input 
                                                            :label="''"
                                                            :type="'number'"
                                                            :name="'weight'"
                                                            :required=false
                                                            :width="90"
                                                            v-model="formValues.weight"
                                                            :fnName="none()"
                                                            :readOnly="isDetail"
                                                        />
                                                        <label>kg</label>
                                                    </div>
                                                </td>
                                                <th>본인 키</th>
                                                <td>
                                                    <div class="spaceBetween">
                                                        <Input 
                                                            :label="''"
                                                            :type="'number'"
                                                            :name="'height'"
                                                            :required=false
                                                            :width="90"
                                                            v-model="formValues.height"
                                                            :fnName="none()"
                                                            :readOnly="isDetail"
                                                        />
                                                        <label>cm</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>부 체중</th>
                                                <td>
                                                    <div class="spaceBetween">
                                                        <Input 
                                                            :label="''"
                                                            :type="'number'"
                                                            :name="'fatherWeight'"
                                                            :required=false
                                                            :width="90"
                                                            v-model="formValues.fatherWeight"
                                                            :fnName="none()"
                                                            :readOnly="isDetail"
                                                        />
                                                        <label>kg</label>
                                                    </div>
                                                </td>
                                                <th>부 키</th>
                                                <td>
                                                    <div class="spaceBetween">
                                                        <Input 
                                                            :label="''"
                                                            :type="'number'"
                                                            :name="'fatherHeight'"
                                                            :required=false
                                                            :width="90"
                                                            v-model="formValues.fatherHeight"
                                                            :fnName="none()"
                                                            :readOnly="isDetail"
                                                        />
                                                        <label>cm</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>모 체중</th>
                                                <td>
                                                    <div class="spaceBetween">
                                                        <Input 
                                                            :label="''"
                                                            :type="'number'"
                                                            :name="'motherWeight'"
                                                            :required=false
                                                            :width="90"
                                                            v-model="formValues.motherWeight"
                                                            :fnName="none()"
                                                            :readOnly="isDetail"
                                                        />
                                                        <label>kg</label>
                                                    </div>
                                                </td>
                                                <th>모 키</th>
                                                <td>
                                                    <div class="spaceBetween">
                                                        <Input 
                                                            :label="''"
                                                            :type="'number'"
                                                            :name="'motherHeight'"
                                                            :required=false
                                                            :width="90"
                                                            v-model="formValues.motherHeight"
                                                            :fnName="none()"
                                                            :readOnly="isDetail"
                                                        />
                                                        <label>cm</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>기타사항</th>
                                                <td colspan="3">
                                                    <v-textarea flat solo density="compact" hide-details="auto" :readonly="isDetail" v-model="formValues.otherDetails"></v-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    <p class="styleTit">분석이력</p>
                                    <RecordList :patientNo="patientNo" :isDetail="isDetail" :requestNo="requestNo" />
                                </li>
                            </ul>
                        </v-form>
                        <div class="btnW popBtn mt40">
                            <button class="btn default" @click="$router.push('/patients')" v-ripple>{{CONST_LIST}}</button>
                            <button v-if="isDetail && (!requestNo || requestState === 'RETURN')" class="btn solid" @click="checkLastReadingDt()" v-ripple="false">수정</button>
                            <button v-else-if="!isDetail" class="btn solid" @click="modifyPatient();" v-ripple="false">저장</button>
                            <span v-if="isDetail">
                                <button v-if="!requestNo" class="btn default" @click="registRequest()" v-ripple="false">환자 이관신청</button>
                                <button v-else class="btn solid" @click="showRequest()" v-ripple="false">이관신청 확인</button>
                            </span>
                        </div>
                    </div>
                </div>
                <!--//신규환자 등록-->
            </div>
            <!--//콘텐츠-->
            <v-dialog v-model="openErrorPopup" width="320">
                <DefaultPopup
                    :popupText="errorMsg"
                    :secondTxt="CONST_OK"
                    :firstBtn="false"
                    :onClick="() => { openErrorPopup = false }"
                    @closePopup="openErrorPopup = false"
                />
            </v-dialog>
            <v-dialog v-model="openPopup" width="320">
                <DefaultPopup
                    :popupText="'저장하시겠습니까?'"
                    :secondTxt="CONST_OK"
                    :firstBtn="true"
                    :onClick="() => {  modifyProgress();  }"
                    @closePopup="openPopup = false"
                />
            </v-dialog>
            <v-dialog v-model="regReqPopupVisible" width="720">
                <RegistRequestPopup :patientNo="patientNo" @closePopup="closePopup" @checkRequestNo="checkRequestNo"  />
            </v-dialog>
            <v-dialog v-model="showReqPopupVisible" width="500">
                <ShowRequestPopup :requestNo="requestNo" @closePopup="closePopup" @checkRequestNo="checkRequestNo" />
            </v-dialog>
            <v-dialog v-model="delPatientPopVisible" width="320">
                <DefaultPopup
                    :popupText="deleteMsg"
                    :secondTxt="CONST_OK"
                    :firstBtn="true"
                    :onClick="() => {  deleteProgress();  }"
                    @closePopup="delPatientPopVisible = false"
                />
            </v-dialog>
        </div>

</template>

<script>
import API_PATIENT from '../../../API/patient/detail'
import API_UPDATE_PATIENT from '../../../API/patient/update'
import API_DELETE_PATIENT from '../../../API/patient/delete'
import { rules } from '../../../mixin/rules'

export default {
    mixins: [rules],
    components: {
        RecordList: () => import('../record/recordList.vue'),
        RegistRequestPopup: () => import('../../../popup/registReqPopup.vue'),
        ShowRequestPopup: () => import('../../../popup/showReqPopup.vue'),
    },
    inject: ["setRightSideHidden"],
    props: {},
    data() {
        return {
            patientNo: this.$route.params.no,
            isGettingItems: false,
            isDetail: true,
            isModItems: false,
            years: [],
            months: [],
            days: [],
            selectedYear: '',
            selectedMonth: '',
            selectedDay: '',
            formValues: {},
            chkMine: this.$route.params.chkMine,
            openPopup: false,
            openErrorPopup: false,
            errorMsg: '',
            requestNo: null,
            requestState: null,
            regReqPopupVisible:false,
            showReqPopupVisible: false,
            delPatientPopVisible: false,
            deleteMsg: ''
        };
    },
    watch: {
        isDetail: function (value) {
            if (value === false) {
                document.getElementById('scrollArea').scrollTop = 0; 
            }
        }
    },
    created() {
    },
    async mounted() {
        this.setRightSideHidden(false);
        await this.initData(this.patientNo)
        this.setDate()
    },
    destroyed() {
    },
    methods: {
        async initData(no) {
            if (this.isGettingItems) {
                return;
            }
            this.isGettingItems = true;
            let res = await API_PATIENT.request(no);
            if (res.isSuccess) {
                this.formValues = res.result;
                this.requestNo = res.requestNo;
                this.requestState = res.requestState;
                localStorage.setItem('patientInfo', JSON.stringify(this.formValues));
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.isGettingItems = false;
        },
        setDate() {
            let dt = new Date();
            let year = dt.getFullYear();
            let birthArr = this.formValues.birth.split('-');
            let startYear = 1940;
            for (let y = startYear; y <= year; y++) {
                this.selectedYear = birthArr[0]
                this.years.push(y)
            }
            for (let y = (year - 20); y <= year; y++) {
                this.selectedYear = birthArr[0]
                this.years.push(y)
            }
            for (let m = 1; m <= 12; m++) {
                if (m < 10) m = "0" + m
                this.selectedMonth = birthArr[1]
                this.months.push(m)
            }
            for (let d = 1; d <= 31; d++) {
                if (d < 10) d = "0" + d
                this.selectedDay = birthArr[2]
                this.days.push(d)
            }
        },
        getBirth(type, event) {
            switch (type) {
                case 'year':
                    this.selectedYear = event.target.value;
                    break;
                case 'month':
                    this.selectedMonth = event.target.value;
                    break;
                case 'day':
                    this.selectedDay = event.target.value;
                    break;
            }
        },
        modifyPatient() {
            if(!this.validate())
                return false;
            const validate = this.$refs.registForm.validate();
            if (validate) {
                this.openPopup = true;
            }
        },
        async modifyProgress() {
            let birth = this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay;
            this.formValues.birth = birth;
            let res = await API_UPDATE_PATIENT.request(this.formValues)
            if (res.isSuccess) { // 성공
                await this.initData(this.patientNo);
                this.showToast('저장 완료');
                this.isDetail = true;
            } else { // 실패
                this.openErrorPopup = true
                this.errorMsg = res.errorMsg
            }
            this.openPopup = false
        },
        validate(){
            if(!this.lengthCheck('본인 체중은',this.formValues.weight)) return false;
            if(!this.lengthCheck('본인 키는',this.formValues.height)) return false;
            if(!this.lengthCheck('부 체중은',this.formValues.fatherWeight)) return false;
            if(!this.lengthCheck('부 키는',this.formValues.fatherHeight)) return false;
            if(!this.lengthCheck('모 체중은',this.formValues.motherWeight)) return false;
            if(!this.lengthCheck('모 키는',this.formValues.motherHeight)) return false;
            return true;
        },
        lengthCheck(text, value) {
            if(!value || value.length < 1) return true;
            const regex = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
            if (!regex.test(value)) {
                  this.openPopup = false;
                this.showPopup(text+' 5자리 이내의 숫자, 소수점 이하 2자리까지 허용됩니다.');
                return false;
            }
            return true;
        },
        checkLastReadingDt() {
            this.isDetail = false
            if (!this.formValues.lastReadingDt) this.isModItems = true
            else this.isModItems = false
        },
        checkModItem() {
            if (this.isModItems) return this.isDetail
            else return true
        },
        registRequest(){
            this.regReqPopupVisible = true;
        },
        closePopup(){
            if(this.regReqPopupVisible) this.regReqPopupVisible = false;
            if(this.showReqPopupVisible) this.showReqPopupVisible = false;
        },
        checkRequestNo(value){
            if(value) this.requestNo = value;
            this.initData(this.patientNo)

        },
        showRequest(){
            this.showReqPopupVisible = true;
        },
        deletePatient(e){
            e.preventDefault();
            if(this.formValues){
                this.deleteMsg = `${this.formValues.name}(${this.formValues.patientSn}) 님을 삭제하시겠습니까?<br> 삭제하시면 해당 환자의 정보, 분석 이력을 확인하실 수 없습니다.`
            }
            this.delPatientPopVisible = true
        },
        async deleteProgress() {
            let res = await API_DELETE_PATIENT.request(this.patientNo)
            if (res.isSuccess) { // 성공
                this.$router.push({name:'patients'})
            } else { // 실패
                this.openErrorPopup = true
                this.errorMsg = res.errorMsg
            }
            this.openPopup = false
        },
    },
    computed: {
    }
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.tbl{
    overflow: hidden;
}

.styleTit{margin-bottom: 16px;}
.v-input--selection-controls{margin-top:0; padding-top:0;}
.spaceBetween{
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    .input-wrapper{
        margin-bottom: 0;
        ::v-deep .v-text-field__slot {
            input {
                text-align: right;
            }
        }
        
    }
}
::v-deep .v-input__slot{
    padding: 0 !important;
    .v-text-field__slot {
        margin-right: 0 !important;
        textarea{
            margin-top: 0 !important;
            padding: 5px !important;
        }
    }
}

.v-application {
    #contents {
        ::v-deep .primary--text {
            color: var(--color-point) !important;
        }
    }
}
.v-input__slot{padding:0;}
.tblScroll{height: auto !important; max-height: 600px;}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{padding:0 !important;}

button{
  transition: box-shadow 0.5s, opacity 0.5s;
}
button:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}

</style>